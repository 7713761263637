.title {
  font-family: $family-serif-headline;
  text-transform: uppercase;
  font-weight: 900;
  a {
    color: inherit;
  }
}

.subtitle {
  text-transform: uppercase;
}