.container
  margin: 0 auto
  position: relative
  +from($desktop)
    max-width: $desktop - (2 * $gap)
    width: $desktop - (2 * $gap)
    &.is-fluid
      margin-left: $gap
      margin-right: $gap
      max-width: none
      width: auto
  +until($widescreen)
    &.is-widescreen
      max-width: $widescreen - (2 * $gap)
      width: auto
  +until($fullhd)
    &.is-fullhd
      max-width: $fullhd - (2 * $gap)
      width: auto
  +from($widescreen)
    max-width: $widescreen - (2 * $gap)
    width: $widescreen - (2 * $gap)
  +from($fullhd)
    max-width: $fullhd - (2 * $gap)
    width: $fullhd - (2 * $gap)