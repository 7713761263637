////////////////////////////////////////////////
////////////////////////////////////////////////
// 1. Initial variables

// Colors
$grey-light: #999999;
$grey-lighter: #CCCCCC;
$grey-dark: #666666;
$grey-darker: #333;
$gold: #8D8A5B;
$gold-invert: #666666;

// Typography
@import url('https://fonts.googleapis.com/css?family=Fauna+One|Playfair+Display:400,700,900');
$family-serif: 'Fauna One', serif;
$family-serif-headline: 'Playfair Display', serif;

$size-1: 3.750rem; // 60px
$size-2: 2.5rem; // 40px
$size-3: 2rem; // 30px
$size-4: 1.250em; // 20px
$size-5: 1.125em; // 18px
$size-6: 1em; // 16px
$size-7: 0.875em; // 14px

// Body
$body-background-color: #121212;
$body-color: $grey-lighter;
$link-hover: $gold;
$body-size: 16px;

// Responsiveness
// 960, 1152, and 1728 have been chosen because they are divisible by both 12 and 16.
$tablet: 769px;
// 960px container + 40px
$desktop: 1000px;
// 1200px container + 40
$widescreen: 1240px;
// 1728px container + 40
$fullhd: 1768px;

// Miscellaneous
$radius: 0px;


////////////////////////////////////////////////
////////////////////////////////////////////////
// 2. Primary colors
$primary: $gold;
$primary-invert: $gold-invert;


////////////////////////////////////////////////
////////////////////////////////////////////////
// 3. Applied variables

// Link colors
$link-active: $white;
$link-focus: $white;

// Text colors
$text-strong: $white;

////////////////////////////////////////////////
////////////////////////////////////////////////
// 4. Lists and maps


////////////////////////////////////////////////
////////////////////////////////////////////////
// 5. Partial overrides.

// Generic
$body-line-height: 1.8;
$family-primary: $family-serif;
$hr-background-color: $grey-darker;
$hr-height: 3px;
$transition: 0.3s ease-in-out;

// Title
$title-color: $grey-dark;

// Navbar
$navbar-background-color: rgba(0, 0, 0, 0.8);
$navbar-height: calc(100px - 4rem);

// Footer
$footer-background-color: #191919;

// Columns
$column-gap: 1.5rem;

// Content
$content-table-row-hover-background-color: transparent;
$content-table-cell-border: none;
$content-table-cell-padding: 1rem;

// Pagination
$pagination-color: $grey-darker;
$pagination-border-color: $grey-dark;
$pagination-active-color: $white;
$pagination-disabled-color: $grey-dark;
$pagination-disabled-background-color: $grey-darker;
$pagination-disabled-border-color: $grey-darker;
$pagination-current-color: $white;
$pagination-current-background-color: transparent;
$pagination-current-border-color: $primary;

// Button
$button-color: $primary;
$button-background-color: transparent;
$button-border-color: $grey-dark;
$button-focus-box-shadow-size: 0 0;
//$button-hover-color: $link-hover;
//$button-hover-border-color: $link-hover-border;

//$button-focus-border-color: $link-focus-border;
//$button-focus-box-shadow-size: 0 0 0 0.125em;
//$button-focus-box-shadow-color: rgba($link, 0.25);
//
//$button-active-color: $link-active;
//$button-active-border-color: $link-active-border;
//
//$button-link-color: $text;
//$button-link-hover-background-color: $background;
//$button-link-hover-color: $text-strong;
//
//$button-disabled-background-color: $white;
//$button-disabled-border-color: $grey-lighter;
//$button-disabled-shadow: none;
//$button-disabled-opacity: 0.5;
//
//$button-static-color: $grey;
//$button-static-background-color: $white-ter;
//$button-static-border-color: $grey-lighter;
