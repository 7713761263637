.content-header {
  display: inline-block;
  position: relative;
  &:before,
  &:after {
    border-right: solid 2px $grey-dark;
    content: '';
    height: calc(4.5rem + 4px);
    width: 1px;
    position: absolute;
    top: 0.75rem;
    right: -3rem;
    transform: rotate(-25deg);
    display: none;
    @include tablet {
      display: inline-block;
    }
  }
  &:after {
    right: calc(-3rem - 1.5rem);
  }
}

.content {
  a {
    font-weight: bold;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $family-serif-headline;
  }
  h2 {
    font-size: $size-2;
  }
  h3 {
    font-size: $size-2;
  }
  h4 {
    &:not(:first-child) {
      margin-top: 1.3333em;
    }
  }
  thead {
    display: none;
  }
  td:first-child {
    font-size: $size-4;
    padding-left: 0;
  }
  td:not(:first-child) {
    padding: ($content-table-cell-padding + 0.5rem) $content-table-cell-padding;
  }
  img {
    margin: 1.5rem 0;
  }
}

.content figure {
  &:after {
    background: url('../images/brush-overlay-content-pictures.png') no-repeat;
    width: 150px;
    height: 66px;
    content: '';
    display: block;
    position: absolute;
    top: 1.5rem;
    right: 50%;
    margin-right: -75px;
  }
  margin: 0 0 3rem;
  position: relative;
  img {
    display: block;
  }
}

.content figure figcaption {
  background-color: rgba(0, 0, 0, 0.60);
  color: $grey-light;
  font-family: $family-serif-headline;
  text-transform: uppercase;
  font-style: normal;
  position: absolute;
  width: 100%;
  top: 50%;
  padding: 1.5rem 0;
  margin-top: -3rem;
}

p img {
  padding: 1.5em;
  border: solid 1px #ccc;
}
