$nav-height: 3.25rem !default

// Components

.nav-toggle
  +hamburger($nav-height)
  // Responsiveness
  +tablet
    display: none

.nav-item
  align-items: center
  display: flex
  flex-grow: 0
  flex-shrink: 0
  font-size: $size-normal
  justify-content: center
  line-height: 1.5
  padding: 0.5rem 0.75rem
  a
    flex-grow: 1
    flex-shrink: 0
  img
    max-height: 1.75rem
  .tag
    &:first-child:not(:last-child)
      margin-right: 0.5rem
    &:last-child:not(:first-child)
      margin-left: 0.5rem
  // Responsiveness
  +mobile
    justify-content: flex-start

.nav-item a:not(.button),
a.nav-item:not(.button)
  color: $text-light
  &:hover
    color: $link-hover
  // Modifiers
  &.is-active
    color: $link-active
  &.is-tab
    border-bottom: 1px solid transparent
    border-top: 1px solid transparent
    padding-bottom: calc(0.75rem - 1px)
    padding-left: 1rem
    padding-right: 1rem
    padding-top: calc(0.75rem - 1px)
    &:hover
      border-bottom-color: $primary
      border-top-color: transparent
    &.is-active
      border-bottom: 3px solid $primary
      color: $primary
      padding-bottom: calc(0.75rem - 3px)
  // Responsiveness
  +desktop
    &.is-brand
      padding-left: 0

// Containers

.nav-left,
.nav-right
  +overflow-touch
  align-items: stretch
  display: flex
  flex-grow: 1
  flex-shrink: 0
  max-width: 100%
  overflow: auto
  +widescreen
    flex-basis: 0

.nav-left
  justify-content: flex-start
  white-space: nowrap

.nav-right
  justify-content: flex-end

.nav-center
  align-items: stretch
  display: flex
  flex-grow: 0
  flex-shrink: 0
  justify-content: center
  margin-left: auto
  margin-right: auto

.nav-menu
  // Responsiveness
  +mobile
    &.nav-right
      background-color: $white
      box-shadow: 0 4px 7px rgba($black, 0.1)
      left: 0
      display: none
      right: 0
      top: 100%
      position: absolute
      .nav-item
        border-top: 1px solid rgba($border, 0.5)
        padding: 0.75rem
      &.is-active
        display: block

// Main container

.nav
  align-items: stretch
  background-color: $white
  display: flex
  height: $nav-height
  position: relative
  text-align: center
  z-index: 10
  & > .container
    align-items: stretch
    display: flex
    min-height: $nav-height
    width: 100%
  // Modifiers
  &.has-shadow
    box-shadow: 0 2px 3px rgba($black, 0.1)
