// 1. Import the initial variables
@import "../libraries/bulma/sass/utilities/initial-variables";
// 2. Set your own initial variables
@import "utilities/variables";
// 4. Import the rest of Bulma
@import "../libraries/bulma/bulma";
@import "base/generic";
@import "components/navbar";
@import "components/pagination";
@import "elements/button";
@import "elements/title";
@import "elements/content";
@import "blueprints/cookieconsent";
@import "blueprints/blog-article";
@import "blueprints/galerie-item";
@import "blueprints/ausstellung-item";
@import "layout/footer";
@import "layout/section";

// @todo: Crop background images for mobile.

// Sticky footer.
html {
  display: flex;
}
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  flex: 1 1 auto;
  flex-flow: column nowrap;
}

.section {
  flex: 1 1 auto;
}

//@include desktop() {
.navbar-burger {
  display: flex;
}

.navbar-menu {
  background: url('../images/artz4you-burkard-index-hero.jpg') center center;
  background-size: cover;
  display: flex;
  height: 100%;
  width: 100%;
  flex-grow: 0;
  align-items: center;
  transition: .5s ease-in-out all;
  transform: translate3d(-100%, 0, 0);
  position: fixed;
}

.navbar-menu.is-active {
  transform: translate3d(0, 0, 0);
}

.navbar-menu-inner {
  background-color: rgba(0, 0, 0, 1);
  height: 100%;
  width: 100%;
  transition: 1s ease-in-out all;
  .container {
    margin-top: calc(100px + 2vh);
    @include mobile {
      background-color: rgba(0, 0, 0, 0.4);
      margin-top: calc(100px);
      height: calc(100vh - 100px);
      padding: 0 0 2rem;
      overflow: scroll;
    }
    ul {
      //overflow: scroll;
    }
  }
  @include desktop() {
    display: flex;
    align-items: center;
    .container {
      margin-top: 0;
    }
  }
}

.navbar-menu.is-active .navbar-menu-inner {
  background-color: rgba(0, 0, 0, 0.8);
}

.navbar, .navbar-menu, .navbar-start, .navbar-end {
}

//}

.is-front .header-image {
  background: url('../images/artz4you-burkard-index-hero.jpg') bottom center no-repeat;
  background-size: cover;
}

.is-front section + section,
.content + section {
  margin-top: 6rem;
}

.header-image {
  background: url('../images/artz4you-burkard-unterseiten-artwork.jpg') bottom center no-repeat;
  background-size: cover;
}

.hero {
  &.is-custom {
    @include tablet {
      background-color: $body-background-color;
      //  min-height: 50vh;
      //  max-height: 100vh;
      background-size: auto;
      //  background-position: center bottom;
      height: 862px;
      width: 100%;
      //  min-height: auto;
      //  .hero-body {
      //    align-items: center;
      //    display: flex;
      //  }
      //  & > .container {
      //    flex-grow: 1;
      //    flex-shrink: 1;
      //  }
      //}
      //@include desktop {
      //  .hero-body {
      //    padding-bottom: 27rem;
      //    padding-top: 27rem;
      //    //align-items: center;
      //    //display: flex;
      //  }
    }
    @media all and (max-height: 862px) {
      height: 100vh;
      background-size: cover;
    }
  }
}

@keyframes arrow {
  0% {
    margin-top: -14px;
  }
  50% {
    margin-top: 0;
  }
  100% {
    margin-top: -14px;
  }
}

.hero.is-fullheight {
  background-size: auto;
  background-position: center top;
  //height: 1120px;
  //width: 100%;
  //min-height: auto;
  @media all and (max-height: 1120px) {
    //min-height: 1120px;
    background-size: cover;
  }

  .scroll-to-body {
    display: block;
    width: 140px;
    height: 80px;
    position: absolute;
    bottom: 6rem;
    left: 50%;
    margin-left: -70px;
    &:hover .arrow {
      &:before,
      &:after {
        animation: arrow 1s linear infinite;
      }
    }
    span {
      display: none;
    }
    .arrow {
      display: block;
      &:before,
      &:after {
        background-color: $gold;
        content: '';
        display: block;
        width: 2px;
        height: 34px;
        left: 50%;
        top: 50%;
        margin-top: -14px;
      }
      &:before {
        transform: rotate(60deg);
        position: absolute;
        margin-left: 14px;
      }
      &:after {
        transform: rotate(-60deg);
        position: absolute;
        margin-left: -14px;
      }
    }
  }
}

.is-front .hero.visible {
  display: flex !important;
}

.hero.visible {
  @include tablet {
    display: flex !important;
  }
}

.hero:not(.is-fullheight) + .section {
  margin-top: calc(100px + 0.75rem);
  @include tablet {
    margin-top: -9rem;
  }
}

.hero.is-fullheight + .section {
  padding-top: calc(100px + 1.5rem);
}

.header {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.galerie-item,
.blog-article,
.ausstellung-item {
  .section {
    margin-top: 120px;
  }
}

/* Center the loader */
#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 99;
  margin: -100px 0 0 -100px;
  border: 4px solid $grey-lighter;
  border-radius: 50%;
  border-top: 4px solid $gold;
  width: 200px;
  height: 200px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0
  }
  to {
    bottom: 0px;
    opacity: 1
  }
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0
  }
  to {
    bottom: 0;
    opacity: 1
  }
}

.header,
.hero,
.section,
.footer {
  display: none;
}

.hero,
.section {
  @extend .animate-bottom;
}
