.footer {
  min-height: 282px;
  padding: 1.5rem 1.5rem 0;
  position: relative;
  &:before {
    background: url('../images/artz4you-burkard-footer-artwork.jpg') no-repeat top center;
    content: '';
    height: 282px;
    width: 100%;
    margin-top: -93px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  @include tablet {
    padding: 6rem 1.5rem;
  }
  @include desktop {
    margin-top: calc(91px + 3rem);
    padding: 12rem 1.5rem;
  }
}

.footer h4 {
  color: $grey-dark;
  font-family: $family-serif-headline;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 1.5rem;
  @include tablet {
    border: solid 1px $grey-dark;
    border-left: none;
    display: inline-block;
  }
}

.footer .container > .columns > .column:not(:first-child) {
  @include tablet {
    padding-bottom: 0;
    position: relative;
    &:before {
      background-color: $grey-dark;
      content: '';
      height: 100%;
      width: 1px;
      position: absolute;
      left: 1.5rem;
      top: 1.5rem;
    }
  }
  & > p,
  & > h4,
  & > h5 {
    @include tablet {
      padding: 0 2.25rem;
    }
  }
}

.footer h5 {
  color: $grey-dark;
  margin-top: -5px;
}

.footer p + h5 {
  margin-top: 1.5rem;
}

.footer .menu-item {
  display: inline;
  @include tablet {
    display: list-item;
  }
}

.menu-footmap .menu--item-link {
  display: inline-block;
  padding: 0 1.5rem 0 0;
  @include tablet {
    display: inline-block;
    margin-bottom: 1.5rem;
    padding: 0 2.25rem;
  }
  &:before {
    content: '';
    background-color: $gold;
    display: block;
    width: 0;
    height: 1px;
    transition: all 0.1s ease-in-out;
    position: absolute;
    top: 14px;
    left: 0;
  }
  &.active:before,
  &:hover:before {
    width: 100%;
  }
  &.active {
    color: $grey-dark;
    cursor: default;
  }
  &.active:before {
    background-color: $grey-dark;
  }
}

.footer .column:first-child {
  .fa:before {
    position: relative;
    top: 1px;
  }
  .fa {
    border: solid 1px $grey-dark;
    color: $grey-lighter;
    width: $body-line-height+em;
    font-size: 1.75rem;
    line-height: $body-line-height;
    text-align: center;
    margin-bottom: 1.5rem;
    margin-right: 0.5rem;
    transition: color $transition, border-color $transition;
    @include tablet {
      color: $grey-dark;
      margin-right: 0.75rem;
    }
    &:hover {
      border-color: $grey-light;
      color: $grey-light;
    }
  }
  img {
    @include desktop {
      margin-top: 3rem;
      margin-bottom: 0;
    }
  }
  .content {
    color: $grey-dark;
    margin-top: 0;
  }
}

.menu-meta {
  display: inline-block;
  padding: 0.75rem 0 0;
  position: relative;
  top: 10px;
  @include tablet {
    display: block;
    position: static;
  }
}

.menu-meta .menu--item-link {
  margin-right: 0.75rem;
  @include tablet {
    margin-bottom: 1.5rem;
  }
}

.menu-meta .menu-item:last-of-type .menu--item-link {
  margin-right: 0;
}
