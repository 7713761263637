.galerie-teaser {
  position: relative;

  &:hover header {
    opacity: 1;
    cursor: pointer;
  }

  header {
    background-color: rgba(0, 0, 0, 0.82);
    position: relative;
    display: block;
    padding: 1.5rem;
    top: -7.5rem;
    margin-bottom: -7.5rem;
    @include tablet {
      opacity: 0;
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      height: calc(100% - 3rem);
      width: calc(100% - 3rem);
      text-align: center;
      padding: 6rem 3rem 0;
      transition: opacity $transition;
    }
    @include widescreen {
      padding-top: 12rem;
    }
  }

  img {
    display: block;
  }

  .title {
    font-weight: 400;
  }

  .title:not(.is-spaced) + .subtitle {
    color: $gold;
    margin-top: -0.75rem;
  }

  .title,
  .subtitle {
    text-transform: none;
  }

  .size {
    width: 100%;
    position: static;
    left: 0;
    bottom: 6rem;
    @include widescreen {
      position: absolute;
      bottom: 12rem;
    }
  }

  .availability {

  }
}


.availability-indicator {
  color: #999;
  font-size: 0.9rem;
  padding: 0 1.5rem;

  &__label {
    display: inline-block;
    color: #fff;
    position: relative;

    &:before {
      border-radius: 50%;
      content: "";
      display: block;
      height: 15px;
      width: 15px;
      position: absolute;
      box-shadow: 2px 2px 2px -2px #000;
      top: 5px;
      left: -22px;
    }
  }

  &__content {

  }

  &.is-request &__label:before {
    background: linear-gradient(90deg, rgb(136, 108, 108) 30%, rgb(107, 102, 102) 100%);
  }

  &.is-not_available &__label:before {
    background: linear-gradient(90deg, rgb(140 8 8) 30%, rgb(183 80 80) 100%);
  }

  &.is-available &__label:before {
    background: linear-gradient(90deg, rgb(8 140 16) 30%, rgb(90 183 80) 100%);
  }
}

.galerie-teaser,
.galerie-item {
  .title {
    color: $white;
  }
}

.galerie-item {
  .subtitle {
    color: $grey-light;
  }

  .is-size-7 {
    color: $grey;
  }

  .availability-indicator {
    &__label {
      //float: left;

      //&:after {
      //  content: ' -';
      //  margin-right: 3px;
      //}
    }

    &__content {
      position: relative;
      left: -20px;
    }
  }
}

.gallery-item--image {
  margin-bottom: 1.5rem;
}

.gallery-item--image-description {
  color: $gold;
  margin-bottom: 1.5rem;
}
