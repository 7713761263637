.button[disabled] {
  //color: $grey-darker;
}

.button.is-large {
  height: 3.75rem;
  line-height: $body-line-height;
  padding: 0.75rem 1.5rem;
}

.button {
  text-transform: uppercase;
  position: relative;
  display: inline-block;

  &:after {
    border: solid 1px transparent;
    display: block;
    content: '';
    width: 0;
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    left: 50%;
    transition: left 0.3s ease-in-out, width 0.3s ease-in-out, border-color 0.3s ease-in-out;
  }
  &:hover:after {
    border-color: $body-background-color;
    width: calc(100% - 1.5rem);
    left: 0.75rem;
  }
  &:focus:after {
    border-color: $body-background-color;
  }
}

.content a.button {
  font-weight: normal;
}
