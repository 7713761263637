.pagination {
  position: relative;
  z-index: 3;
  margin-bottom: 1.5rem;
  @include tablet {
    margin-bottom: 0;
  }
  @include desktop {
    margin-left: 3rem;
  }
}

.pagination-previous,
.pagination-next {
  text-transform: uppercase;
}

.pagination .pagination-next {
  margin-right: 0.25rem;
  margin-left: 0.75rem;
}

.pagination .pagination-previous {
  margin-left: 0.25rem;
  margin-right: 0.75rem;
}

.pagination-link {
  width: 3.75rem;
}

.pagination-previous,
.pagination-next,
.pagination-link {
  height: 3.75rem;
  padding: 0.75rem 1.5rem;
  text-transform: uppercase;
  margin-left: 0.75rem;
}

.pagination.is-single {
  margin-left: 0;
  margin-top: 3rem;

  .pagination-link {
    width: auto;
    margin-left: 0.25rem;
  }
}

.view .pagination-link {
  width: auto;
}

.pagination-list {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  max-width: calc(100vw - 3rem);
}

.pagination-list::-webkit-scrollbar {
  display: none;
}


.pagination, .pagination-list {
  justify-content: left;

  .pagination-link:first-child {
    margin-left: 0;
    margin-right: 0.5rem;
    margin-top: 1.5rem;
    @include tablet {
      margin-top: 0;
      margin-left: 0.5rem;
    }
  }
}
