// Inline
a {
  transition: color $transition, border $transition;
}

hr {
  border-top: solid 2px $black;
  @include desktop {
    margin: 2.5rem 0;
  }
}

strong {
  color: inherit;
}

.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
