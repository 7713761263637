.navbar > .container {
  padding: 2em 1.5rem;
  min-height: inherit;
  @include touch {
    display: flex;
  }
  // @todo: fullhd+40
  @include fullhd {
    padding: 2em 0;
  }
}

@include desktop {
  .container > .navbar .navbar-brand {
    margin-left: 0;
  }
}

.logo img {
  display: block;
}

.navbar-burger {
  background-color: transparent;
  border: none;
  display: flex;
  border-radius: 0;
  color: $grey-lighter;
  width: 60px;
  top: 5px;
  span {
    height: 2px;
    width: 60px;
    margin-left: -30px;
  }
  span:nth-child(1) {
    margin-top: -11px;
  }
  span:nth-child(3) {
    margin-top: 9px;
  }
  &:hover {
    color: #fff;
    span {
      // @todo: Implement smooth animation only for desktop.
      //transition: margin 1s linear;
    }
    span:nth-child(1) {
      //margin-top: -8px;
    }
    span:nth-child(3) {
      //margin-top: 6px;
    }
  }
  &:focus:not(:active),
  &.is-focused:not(:active) {
    box-shadow: none;
  }
  &.is-active {
    color: $grey-lighter;
  }
  &.is-active span:nth-child(1) {
    margin-top: -10px;
    margin-left: -25px;
    transform: rotate(25deg);
    transform-origin: left top;
  }
  &.is-active span:nth-child(3) {
    margin-top: 15px;
    margin-left: -25px;
    transform: rotate(-25deg);
    transform-origin: left bottom;
  }
}

@include touch {
  .navbar {
    min-height: calc(100px - 16vw);
  }
  .navbar, .navbar-menu, .navbar-start, .navbar-end {
    align-items: stretch;
    display: flex;
  }
}

.navbar-menu.is-active {
  @include touch {
    display: flex;
  }
}

@include touch {
  .navbar-menu {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
}

.menu {
  //@extend .columns;
  //@extend .is-multiline;
  @include mobile {
    &.columns {
      margin: 0;
    }
  }
  @include tablet {
    padding: 2vh 0;
    &:after {
      background-color: #333333;
      content: '';
      display: block;
      height: 100%;
      width: 2px;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -1px;
    }
  }
  @include desktop {
    padding: 6vh 0;
  }
}

.menu--item-link {
  display: block;
  text-transform: uppercase;
  position: relative;
}

.menu--item {
  @extend .column;
  @include mobile {
    border-bottom: solid 2px #000;
    border-top: solid 1px #333333;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @include tablet {
    margin-top: 2vh;
    margin-bottom: 2vh;
    &:nth-child(odd) {
      text-align: right;
      padding-right: 10vw;
    }
    &:nth-child(even) {
      padding-left: 10vw;
    }
  }
  @include desktop {
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
}

.menu--item-link-inner {
  display: inline-block;
  position: relative;
  @include tablet {
    .menu--item:nth-child(odd) & {
      padding-left: 3rem;
    }
    .menu--item:nth-child(even) & {
      padding-right: 3rem;
    }
  }
}

.menu--item__title {
  display: inline-block;
  color: $grey-lighter;
  font-size: 1.5em;
  line-height: 130%;
  font-family: $family-serif-headline;
  font-weight: 900;
  &:before {
    content: '';
    background-color: $grey-lighter;
    display: block;
    width: 0;
    height: 2px;
    transition: all 0.1s ease-in-out;
    position: absolute;
    top: 15px;
    @include tablet {
      top: 25px;
    }
  }
  .menu--item-link.active & {
    color: $primary;
  }
  .menu--item-link.active &:before,
  .menu--item-link:hover &:before {
    width: 100%;
  }
  @include tablet {
    font-size: 2.5em;
    .menu--item-link.active &:before {
      background-color: $primary;
    }
    .menu--item:nth-child(odd) &:before {
      right: -14px;
    }
    .menu--item:nth-child(even) &:before {
      left: -14px;
    }
  }
}

.menu__item__subtitle {
  font-size: 1em;
  line-height: 130%;
  display: block;
  .menu--item-link.active & {
    color: $grey-lighter;
  }
  @include tablet {
    font-size: 1.125em;
  }
}

