div.cc-revoke, div.cc-window {
  font-family: $family-serif;
}

div.cc-window.cc-floating {
  text-align: center;
  padding: 1.25rem;
  max-width: 100%;
  left: 0;
  bottom: 0;
  @include desktop {
    text-align: left;
    max-width: 24em;
    left: 1em;
    bottom: 1em;
  }
}

div.cc-window.cc-floating .cc-btn {
  @extend .button;
}
