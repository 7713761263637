.blog-tags {
  color: $primary;

  span:after {
    content: " /";
  }

  span:last-child:after {
    content: "";
  }
}

.blog-date {
  color: $grey-light;
  font-size: 0.700em;
}

header .pagination {
  @include tablet {
    margin-top: 1.5rem;
  }
  @include desktop {
    float: right;
  }
}

.blog-teaser .button {
  margin-top: 1.5rem;
}

.blog-teaser {
  .title {
  }

  .subtitle:not(.is-spaced) + .title {
    margin-top: -0.75rem;
  }

  .title:after {
    display: none;
  }
}

.blog-teaser,
.blog-article,
.ausstellung-item {
  .title {
    font-weight: 400;
    text-transform: none;
    color: $white;
    margin-bottom: 1.5rem;
  }

  .subtitle {
    text-transform: none;
  }
}
