@use "sass:math"

.tile
  align-items: stretch
  display: block
  flex-basis: 0
  flex-grow: 1
  flex-shrink: 1
  min-height: min-content
  // Modifiers
  &.is-ancestor
    margin-left: -0.75rem
    margin-right: -0.75rem
    margin-top: -0.75rem
    &:last-child
      margin-bottom: -0.75rem
    &:not(:last-child)
      margin-bottom: 0.75rem
  &.is-child
    margin: 0 !important
  &.is-parent
    padding: 0.75rem
  &.is-vertical
    flex-direction: column
    & > .tile.is-child:not(:last-child)
      margin-bottom: 1.5rem !important
  // Responsiveness
  +tablet
    &:not(.is-child)
      display: flex
    @for $i from 1 through 12
      &.is-#{$i}
        flex: none
        width: math.div($i, 12) * 100%
