.austellung-teaser {
  display: block;

  .button {
    margin-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.ausstellung-item .ausstellung-item-date {
  margin-top: 2rem;
  font-size: 0.700em;
}

.ausstellung-item__title {
  //font-family: $family-serif-headline;
}

.ausstellung-item-date {
  display: block;
  color: $grey-light;
  //font-size: 1.25em;

  strong {
    background-color: #fff;
    font-weight: bold;
    font-size: 0.700em;
    color: $body-background-color;
    padding: 1px 5px;
    margin-left: 7px;
    text-transform: uppercase;
    position: relative;
    top: -2px;
  }
}
